var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div-main-list-page" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { staticClass: "top-tab-botton-bar", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 8, md: 10 } },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { width: "150px" },
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getPostedList(
                        _vm.postedListPagination.pageSize,
                        0
                      )
                    },
                  },
                  model: {
                    value: _vm.postedListTabActive,
                    callback: function ($$v) {
                      _vm.postedListTabActive = $$v
                    },
                    expression: "postedListTabActive",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "Pending", name: "VERIFIED" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "Approved", name: "READY_FOR_INVOICING" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "flex-end",
              staticStyle: { "white-space": "nowrap" },
              attrs: { xs: 24, sm: 16, md: 14 },
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "round-input search-bar",
                  attrs: { placeholder: "Search Vessel/Terminal" },
                  nativeOn: {
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.getPostedList(
                        _vm.postedListPagination.pageSize,
                        0
                      )
                    },
                  },
                  model: {
                    value: _vm.searchPostedList,
                    callback: function ($$v) {
                      _vm.searchPostedList = $$v
                    },
                    expression: "searchPostedList",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      type: "primary",
                      icon: "el-icon-search",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getPostedList(
                          _vm.postedListPagination.pageSize,
                          0
                        )
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "white-space": "nowrap",
                  },
                },
                [
                  _vm.postedListTabActive === "VERIFIED" &&
                  _vm.hasPermission("authorized_signatory_approval")
                    ? _c(
                        "el-button",
                        {
                          attrs: { round: "", outline: "", type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.postedListApproval(true)
                            },
                          },
                        },
                        [_vm._v(" Approve ")]
                      )
                    : _vm._e(),
                  (_vm.postedListTabActive === "VERIFIED" &&
                    _vm.hasPermission("authorized_signatory_approval")) ||
                  (_vm.postedListTabActive === "READY_FOR_INVOICING" &&
                    _vm.hasPermission("accounts_approval"))
                    ? _c(
                        "el-button",
                        {
                          attrs: { round: "", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.postedListApproval(false)
                            },
                          },
                        },
                        [_vm._v(" Reject ")]
                      )
                    : _vm._e(),
                  _vm.postedListTabActive === "READY_FOR_INVOICING" &&
                  _vm.hasPermission("accounts_approval")
                    ? _c(
                        "el-button",
                        {
                          attrs: { round: "", type: "primary" },
                          on: { click: _vm.createInvoice },
                        },
                        [_vm._v(" Create Invoice ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "div-main-list-page-table" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.postedListTableData,
                height: "100%",
                "row-key": "id",
                "expand-row-keys": _vm.expandedRowsKey,
                border: true,
                "header-cell-style": _vm.HEADER_CELL_STYLE,
                "cell-style": _vm.CELL_STYLE,
              },
              on: { "expand-change": _vm.handlePostedListExpandChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: props.row.orderItems,
                              "cell-style": _vm.CELL_STYLE,
                              "header-cell-style": {
                                alignSelf: "center",
                                fontSize: "12px",
                              },
                            },
                            on: {
                              "selection-change":
                                _vm.handlePostedListSelectChange,
                            },
                          },
                          [
                            (_vm.postedListTabActive === "VERIFIED" &&
                              _vm.hasPermission(
                                "authorized_signatory_approval"
                              )) ||
                            (_vm.postedListTabActive ===
                              "READY_FOR_INVOICING" &&
                              _vm.hasPermission("accounts_approval"))
                              ? _c("el-table-column", {
                                  attrs: {
                                    type: "selection",
                                    "min-width": "40",
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                label: "Item",
                                "min-width": "250px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.itemType === "PRODUCT"
                                                ? scope.row.sysProductName
                                                : scope.row.description
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Quantity",
                                "min-width": "150px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.itemType ===
                                                "PRODUCT" &&
                                                scope.row.verifiedQuantity
                                                ? _vm.numberWithCommas(
                                                    scope.row.verifiedQuantity,
                                                    3
                                                  )
                                                : _vm.numberWithCommas(
                                                    scope.row.quantity,
                                                    3
                                                  )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Currency",
                                "min-width": "150px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(_vm._s(scope.row.currency)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Price",
                                "min-width": "150px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.itemType ===
                                                "PRODUCT" &&
                                                scope.row.finalBillingPrice
                                                ? _vm.numberWithCommas(
                                                    scope.row.finalBillingPrice,
                                                    3
                                                  )
                                                : _vm.numberWithCommas(
                                                    scope.row.unitPrice,
                                                    3
                                                  )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Amount",
                                "min-width": "150px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.itemType === "PRODUCT"
                                          ? _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.verifiedQuantity &&
                                                    scope.row.finalBillingPrice
                                                    ? _vm.numberWithCommas(
                                                        scope.row
                                                          .verifiedQuantity *
                                                          scope.row
                                                            .finalBillingPrice,
                                                        3
                                                      )
                                                    : "-"
                                                )
                                              ),
                                            ])
                                          : _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.numberWithCommas(
                                                    scope.row.cost,
                                                    3
                                                  )
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Date Delivered",
                                "min-width": "150px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              (scope.row.itemType ===
                                                "PRODUCT" ||
                                                scope.row.itemType ===
                                                  "OTHER_COST") &&
                                                scope.row.supplyDate
                                                ? _vm
                                                    .$moment(
                                                      scope.row.supplyDate
                                                    )
                                                    .format("DD-MM-YYYY")
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Order No.",
                  prop: "salesOrderNo",
                  "min-width": "150px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Vessel/Terminal",
                  "min-width": "150px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              scope.row.orderType === "DELIVERED" ||
                                scope.row.orderType === "BARGING_NOMINATION"
                                ? scope.row.vesselName
                                : scope.row.terminal || "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Customer Company",
                  "min-width": "150px",
                  prop: "buyerCompanyName",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Payment Terms", "min-width": "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.capitalizeFirstLetter(
                                scope.row.paymentTerm,
                                "_"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Order Created By",
                  "min-width": "150px",
                  prop: "sellerName",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.postedListPagination.pageSize,
                  "page-sizes": [5, 10, 20, 50, 100],
                  "current-page": _vm.postedListPagination.pageNumber,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.postedListPagination.total,
                },
                on: {
                  "size-change": _vm.handlePostedListPageSizeChange,
                  "current-change": _vm.handlePostedListPageChange,
                  "update:pageSize": function ($event) {
                    return _vm.$set(
                      _vm.postedListPagination,
                      "pageSize",
                      $event
                    )
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(
                      _vm.postedListPagination,
                      "pageSize",
                      $event
                    )
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div-main-list-page-title" }, [
      _c("h1", [_vm._v("Posted List")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }